<template>
  <label
    :class="baseLabelClass"
    :for="'collection-radio-' + randomId"
    :data-testid="props.dataTestid + '-radio-label'"
  >
    <input
      :id="'collection-radio-' + randomId"
      :data-testid="props.dataTestid + '-radio-input'"
      type="radio"
      v-model="modelValue"
      v-bind="$attrs"
      :class="baseInputClass"
    />
    <span class="flex items-center gap-4">
      <NuxtImg
        src="./images/store-location-icon.png"
        alt="add-to-trolley-modal-collection-radio-store-icon"
        width="32"
        class="shrink-0 inline"
      />

      <div v-if="loading" class="animate-pulse">Getting stock...</div>

      <span v-else>
        <!-- No branch selected -->
        <span v-if="props.availability.status === 'NoBranchSelected'">
          <TsTypography
            as="span"
            size="sm"
            append-class="underline text-danger cursor-pointer"
            @click="emit('setBranch')"
          >
            Set your local branch.
          </TsTypography>
          <br />
          <TsTypography as="span" size="sm">
            Available to collect within few mins
          </TsTypography>
        </span>
        <!-- Available for collection at the selected branch -->
        <span v-if="props.availability.status === 'Collection'">
          <TsTypography as="span" variant="h4">
            Click and collect at
            <strong>
              {{ branchName }}
            </strong>
          </TsTypography>
          <!-- in <span class="text-primary">15 Mins</span> </TsTypography
              ><br />
              <TsTypography as="span" size="sm">
                Order within
                <span class="text-idle-black">06h:18m</span> </TsTypography
              > -->
          <br />
          <TsTypography as="span" size="sm">
            {{
              props.availability.stock > 20 ? "20+" : props.availability.stock
            }}
            available
          </TsTypography>
        </span>
        <!-- Available for Next Day Collection at the selected branch -->
        <span v-if="props.availability.status === 'NextDayCollection'">
          <TsTypography
            as="span"
            variant="h4"
            weight="medium"
            append-class="text-primary"
          >
            Available for next day collection at
            <strong>
              {{ branchName }}
            </strong>
          </TsTypography>
          <!-- in <span class="text-primary">15 Mins</span> </TsTypography
              ><br />
              <TsTypography as="span" size="sm">
                Order within
                <span class="text-idle-black">06h:18m</span> </TsTypography
              > -->
          <br />
          <TsTypography
            as="span"
            size="sm"
            :data-testid="props.dataTestid + '-available-stock'"
          >
            {{
              props.availability.stock > 20 ? "20+" : props.availability.stock
            }}
            available
          </TsTypography>
        </span>

        <!-- Out of stock completely -->
        <span
          v-if="props.availability.status === 'OutOfStock'"
          class="ms-auto flex justify-between items-center gap-2"
        >
          <TsTag
            variant="danger"
            :data-testid="props.dataTestid + '-out-of-stock-tag'"
            append-class="outline-0"
            size="xs"
            rounded
            outlined
          >
            {{ `Out of Stock at ${branchName}` }}
          </TsTag>
        </span>

        <TsTypography
          v-if="props.availability.status !== 'NoBranchSelected'"
          class="ms-auto my-1 text-primary underline hover:no-underline cursor-pointer"
          weight="medium"
          size="sm"
          :data-testid="props.dataTestid + '-change-branch-option'"
          @click="emit('setBranch')"
        >
          change branch
        </TsTypography>
      </span>
    </span>
  </label>
</template>

<script lang="ts" setup>

/* SCHEMA  -----> 

availability = {
	status: "Collection" 
	stock: 7
} or,
	{
		status: "NextDayCollection" 
		stock: 25 (delivery stock)
}
or {
	status: "OutOfStockAll",
	stock: 0 
}
or {
	status: "NoBranchSelected",
	stock: 0 
}

*/

const AvailabilityStatus = [
  "Collection",
  "NextDayCollection",
  "OutOfStock",
  "NoBranchSelected",
] as const;

export type ProductAvailability = {
  status: (typeof AvailabilityStatus)[number];
  stock: number;
};

type Props = {
  availability: ProductAvailability;
  dataTestid: string;
  loading?: boolean;
  branchName?: string;
};
const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  setBranch: any;
}>();

defineOptions({
  inheritAttrs: false,
});

const modelValue = defineModel();

const baseInputClass =
  "size-5 text-info disabled:cursor-not-allowed bg-transparent focus:ring-info-border focus:ring-2";

const baseLabelClass =
  "flex items-center has-[:checked]:border-info w-full space-x-2.5 border hover:bg-info/10 transition-colors cursor-pointer has-[:disabled]:cursor-not-allowed p-3.5 rounded-md border-natural-light-grey";

// random ID for each Vue Instance
const randomId = ref<string>("");

onMounted(() => {
  randomId.value = useRandomUUID();
});
</script>
